<template>
  <vs-row id="profile-page">
    <vx-card class="accordin-card">
      <div class="vx-card__body bsv p-0">
        <vs-collapse accordion>
          <vs-collapse-item open>
            <div slot="header">
              <h3 class="font-normal text-left">Your details</h3>
            </div>
            <div class="wrapper mt-5 flex flex-wrap gap-4">
              <div class="image-container">
                <img
                  v-if="activeUserInfo.photoURL"
                  key="onlineImg"
                  :src="
                    activeUserInfo.photoURL
                      ? activeUserInfo.photoURL
                      : activeUserInfo.profileImage
                  "
                  alt="user-img"
                  class="shadow-md cursor-pointer block photo"
                />
              </div>

              <div class="info-container sm:pt-5 md:pt-5 lg:pt-4">
                <h4 class="pt-2">{{ userDetail.fullName }}</h4>
                <p class="">{{ userDetail.email }}</p>
                <p class="">{{ userDetail.phoneNumber }}</p>
              </div>

              <div class="edit-btn">
                <vs-button
                  v-round
                  class="btn only-border-btn"
                  @click="showProfileModal = true"
                  >Edit</vs-button
                >
              </div>
            </div>
          </vs-collapse-item>
        </vs-collapse>
      </div>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item>
          <div slot="header">
            <h3 class="font-normal text-left">Login</h3>
          </div>

          <vs-row>
            <vs-col vs-align="center" vs-type="flex" vs-w="6">
              <vs-input
                v-model="this.activeUserInfo.flipPassword"
                class="w-7/12"
                label="Password"
                type="password"
              />
            </vs-col>

            <vs-col
              vs-align="center"
              vs-justify="flex-end"
              vs-type="flex"
              vs-w="6"
            >
              <vs-button
                v-round
                class="btn h-11 only-border-btn"
                @click="showPassswordModal = true"
                >Change Password</vs-button
              >
            </vs-col>
          </vs-row>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <vx-card class="accordin-card mt-5">
      <vs-collapse accordion>
        <vs-collapse-item ref="notifyCollapse">
          <div slot="header">
            <h3 class="font-normal text-left">Notifications</h3>
          </div>

          <p class="italic">
            Set up what notifications you want the system to generate and how
            you’d like to receive them.
          </p>
          <div class="flex flex-col my-10 row-gap-5">
            <div class="flex">
              <label class="w-4/12 text-lg font-bold" for="pages"
                >Set notification preferences for</label
              >

              <vs-select
                placeholder="Select option"
                class="lg:w-4/12 md:w-6/12"
                v-model="selectedTemplate"
                name="pages"
                id="pages"
                v-validate="'required'"
                data-vv-as="Page"
                data-vv-validate-on="blur"
                @change="checkSelected($event)"
              >
                <vs-select-item
                  :class="template.templateType=='HOSTED'?'hosted':'template'"
                  :key="index"
                  :value="template"
                  :text="template.templateName"
                  v-for="(template, index) in requestTemplates"
                />
              </vs-select>
            </div>

            <span class="text-danger text-sm">
              {{ errors.first("pages") }}
            </span>
          </div>
          <div v-if="showNotify">
            <div
              class="
                grid
                lg:grid-cols-3
                md:grid-cols-2
                sm:grid-cols-1
                gap-4
                mb-20
              "
            >
              <radio-box
                v-model="notificationSetting"
                description="Use global notification settings"
                name="notification"
                radioValue="DEFAULT"
                reverse
                title="Default"
                @input="notifyChange('default')"
              ></radio-box>

              <radio-box
                v-model="notificationSetting"
                description="Set custom notification preferences"
                name="notification"
                radioValue="CUSTOM"
                reverse
                title="Custom"
                @input="notifyChange('custom')"
              ></radio-box>

              <radio-box
                v-model="notificationSetting"
                description="Turn off all notifications"
                name="notification"
                radioValue="OFF"
                reverse
                title="Off"
                @input="notifyChange('off')"
              ></radio-box>
            </div>

            <h5 class="notificaton-title font-semibold" v-if="prflag">
              Payment request notifications
            </h5>
            <vs-table :data="prNotification" class="pb-10" v-if="prflag">
              <template slot="thead">
                <vs-th> Status </vs-th>
                <vs-th> Description </vs-th>
                <vs-th>
                  <div class="flex flex-col">
                    <label class="w-full" for="prNotifications_forEmail"
                      >Email</label
                    >
                    <vs-switch
                      id="prNotifications_forEmail"
                      v-model="prNotificationsCheckbox.forEmail"
                      :color="switchColor"
                      :disabled="isDisabled"
                      name="prNotifications_forEmail"
                      @click="check($event, prNotificationsCheckbox.forEmail)"
                    />
                  </div>
                </vs-th>
                <vs-th>
                  <div class="flex flex-col">
                    <label class="w-full" for="prNotifications_forDashboard"
                      >Dashboard</label
                    >
                    <vs-switch
                      id="prNotifications_forDashboard"
                      v-model="prNotificationsCheckbox.forDashboard"
                      :color="switchColor"
                      :disabled="isDisabled"
                      name="prNotifications_forDashboard"
                      @click="
                        check($event, prNotificationsCheckbox.forDashboard)
                      "
                    />
                  </div>
                </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                  <vs-td :data="tr.status">
                    {{ tr.status }}
                  </vs-td>

                  <vs-td :data="tr.description">
                    {{ tr.description }}
                  </vs-td>

                  <vs-td :data="tr.forEmail">
                    <div class="flex justify-left">
                      <vs-checkbox
                        v-model="tr.forEmail"
                        :disabled="isDisabled"
                      ></vs-checkbox>
                    </div>
                  </vs-td>
                  <vs-td :data="tr.forDashboard" class="flex justify-left">
                    <div class="flex justify-left">
                      <vs-checkbox
                        v-model="tr.forDashboard"
                        :disabled="isDisabled"
                      ></vs-checkbox>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>

            <h5 class="notificaton-title font-semibold mt-10" v-if="txflag">
              Transaction notifications
            </h5>
            <vs-table :data="txNotification" class="mb-20 mt-10" v-if="txflag">
              <template slot="thead">
                <vs-th> Status </vs-th>
                <vs-th> Description </vs-th>
                <vs-th>
                  <div class="flex flex-col">
                    <label class="w-full" for="txNotifications_forEmail"
                      >Email</label
                    >
                    <vs-switch
                      id="txNotifications_forEmail"
                      v-model="transactionNotificationsCheckbox.forEmail"
                      :color="switchColor"
                      :disabled="isDisabled"
                      @click="
                        check($event, transactionNotificationsCheckbox.forEmail)
                      "
                    />
                  </div>
                </vs-th>
                <vs-th>
                  <div class="flex flex-col">
                    <label class="w-full" for="txNotifications_forDashboard"
                      >Dashboard</label
                    >
                    <vs-switch
                      id="txNotifications_forDashboard"
                      v-model="transactionNotificationsCheckbox.forDashboard"
                      :color="switchColor"
                      :disabled="isDisabled"
                      @click="
                        check(
                          $event,
                          transactionNotificationsCheckbox.forDashboard
                        )
                      "
                    />
                  </div>
                </vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(tr, indextr) in data" :key="indextr">
                  <vs-td :data="tr.status">
                    {{ tr.status }}
                  </vs-td>

                  <vs-td :data="tr.description">
                    {{ tr.description }}
                  </vs-td>

                  <vs-td :data="tr.forEmail">
                    <div class="flex justify-left">
                      <vs-checkbox
                        v-model="tr.forEmail"
                        :disabled="isDisabled"
                      ></vs-checkbox>
                    </div>
                  </vs-td>
                  <vs-td :data="tr.forDashboard" class="flex justify-left">
                    <div class="flex justify-left">
                      <vs-checkbox
                        v-model="tr.forDashboard"
                        :disabled="isDisabled"
                      ></vs-checkbox>
                    </div>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </vx-card>

    <pop-up :errorsList="errors" form-name="NotificationForm"></pop-up>
    <leave-popup
        :popup-active="popupActive"
        @handleClick="popActions"
      ></leave-popup>

    <div class="actions mt-5 text-right">
      <vs-button
        v-round
        class="btn btn-primary pl-20 pr-20"
        @click="saveNotifications"
        >Save</vs-button
      >
      <span v-round class="btn pl-20 pr-20 ml-5 edit-profile" color="danger"
        >Cancel</span
      >
    </div>
    <!-------- ALL POPUPS ------------>

    <vs-popup :active.sync="showProfileModal" title="Edit your details">
      <vs-row>
        <vs-col
          class="flex flex-col gap-y-8"
          vs-align="center"
          vs-type="flex"
          vs-w="4"
        >
          <img
            v-if="activeUserInfo.photoURL"
            key="onlineImg"
            :src="dataImg ? dataImg : userDetail.photoURL"
            alt="user-img"
            class="rounded-full shadow-md cursor-pointer block photo"
            height="150"
            width="150"
          />
          <input
            ref="updateImgInput"
            v-validate="'image'"
            accept="image/*"
            class="hidden"
            data-vv-as="image"
            name="image_field"
            type="file"
            @change="updateCurrImg"
          />
          <vs-button
            v-round
            class="btn px-6 block"
            @click="$refs.updateImgInput.click()"
            >Change Image</vs-button
          >
          <span class="text-danger text-sm">
            {{ errors.first("image_field") }}
          </span>
        </vs-col>

        <vs-col
          class="flex flex-col gap-y-1 pt-5 pb-5"
          vs-align="center"
          vs-type="flex"
          vs-w="6"
        >
          <vs-input
            v-model="userDetail.fullName"
            v-validate="'required'"
            class="w-full mb-3"
            data-vv-as="full name"
            data-vv-validate-on="blur"
            label="Full Name"
            name="fullName"
            size="large"
          />
          <span class="text-danger text-sm">
            {{ errors.first("fullName") }}
          </span>
          <vs-input
            v-model="userDetail.email"
            v-validate="'required|email'"
            class="w-full mb-3"
            data-vv-as="email"
            data-vv-validate-on="blur"
            label="Email"
            name="email"
            size="large"
          />
          <span class="text-danger text-sm">
            {{ errors.first("email") }}
          </span>

          <vs-input
            v-model="userDetail.phoneNumber"
            v-validate="'required'"
            class="w-full"
            data-vv-as="phone number"
            data-vv-validate-on="blur"
            label="Phone Number"
            name="phoneNumber"
            size="large"
          />
          <span class="text-danger text-sm">
            {{ errors.first("phoneNumber") }}
          </span>
        </vs-col>
      </vs-row>
      <div class="actions mt-2 text-right w-full">
        <vs-button
          v-round
          class="btn btn-primary pl-10 pr-10"
          @click="updateAdminProfileData"
          >Save</vs-button
        >
        <span
          v-round
          class="btn ml-5 edit-profile"
          @click="showProfileModal = false"
          >Cancel</span
        >
      </div>
    </vs-popup>

    <vs-popup :active.sync="showPassswordModal" title="Change Password">
      <div class="vx-row">
        <div
          class="
            vx-col
            sm:w-full
            md:w-full
            w-full
            mx-auto
            self-center
            d-theme-dark-bg
          "
        >
          <div class="p-8">
            <form class="postion-icon">
              <div class="passwordField">
                <vs-input
                  id="currentPassword"
                  v-model="user.currentPassword"
                  v-validate="'required'"
                  :icon="passwordShowIcon"
                  :type="passwordFieldType"
                  class="passwordInput"
                  data-vv-validate-on="blur"
                  icon-after="true"
                  icon-no-border
                  icon-pack="feather"
                  label-placeholder="Current Password"
                  name="currentPassword"
                  @click.native="
                    switchPasswordVisibility($event, 'currentPassword')
                  "
                />
                <span class="text-danger text-sm">{{
                  errors.first("currentPassword")
                }}</span>
              </div>
              <div class="passwordField">
                <vs-input
                  id="newPassword"
                  v-model="user.newPassword"
                  v-validate="'required|passwordFormat'"
                  :icon="newPasswordShowIcon"
                  :type="newPasswordFieldType"
                  class="passwordInput"
                  data-vv-validate-on="blur"
                  icon-after="true"
                  icon-no-border
                  icon-pack="feather"
                  label-placeholder="New Password"
                  name="newPassword"
                  @click.native="
                    switchPasswordVisibility($event, 'newPassword')
                  "
                />
                <span class="text-danger text-sm">{{
                  errors.first("newPassword")
                }}</span>
              </div>
              <div class="passwordField">
                <vs-input
                  id="confirmNewPassword"
                  v-model="user.confirmNewPassword"
                  v-validate="'required'"
                  :icon="confirmPasswordShowIcon"
                  :type="confirmPasswordFieldType"
                  class="passwordInput"
                  data-vv-validate-on="blur"
                  icon-after="true"
                  icon-no-border
                  icon-pack="feather"
                  label-placeholder="Confirm New Password"
                  name="confirmNewPassword"
                  @click.native="
                    switchPasswordVisibility($event, 'confirmPassword')
                  "
                />
                <span class="text-danger text-sm">{{
                  errors.first("confirmNewPassword")
                }}</span>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="actions mt-2 text-right w-full">
        <vs-button
          v-round
          :disabled="!validateForm"
          class="btn btn-primary pl-10 pr-10"
          @click="changeAdminPassword"
          >Save</vs-button
        >
        <span
          v-round
          class="btn ml-5 edit-profile"
          @click="showPassswordModal = false"
          >Cancel</span
        >
      </div>
    </vs-popup>
  </vs-row>
</template>

<script>
import ProfileDetail from "@/views/components/profile/ProfileDetail";
import ProfileEdit from "@/views/components/profile/ProfileEdit";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import RadioBox from "@/views/components/radiobox";
import MultiSelectExtended from "@/views/components/MultiSelectExtended";
import _ from "lodash";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";

const dictionary = {
  en: {
    attributes: {
      currentPassword: "current password",
      newPassword: "new password",
      confirmNewPassword: "confirm new password",
    },
  },
};
Validator.localize(dictionary);
export default {
  components: {
    ProfileEdit,
    ProfileDetail,
    MultiSelectExtended,
    RadioBox,
    PopUp,
    LeavePopup,
  },
  data() {
    return {
      requestTemplates: [],
      notificationSetting: "DEFAULT",
      switch1: "",
      prNotifications: {
        email: "",
        dashboard: "",
      },
      transactionNotifications: {
        email: "",
        dashboard: "",
      },
      edit: false,
      checked: "",
      nextObj: '',// determines the next route,
      isSaved: false,
      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      showProfileModal: false,
      switchColor: "#0F67F4",
      pages: [],
      selectedTemplate: "",
      isSelectAll: false,
      activeModal: false,
      showPassswordModal: false,
      userDetail: {},
      previousEmail: "",
      newImg: null,
      dataImg: null,
      checkBox1: false,
      passwordFieldType: "password",
      passwordShowIcon: "icon icon-eye-off",
      newPasswordFieldType: "password",
      newPasswordShowIcon: "icon icon-eye-off",
      confirmPasswordFieldType: "password",
      confirmPasswordShowIcon: "icon icon-eye-off",
      iconWrap: {
        marginTop: "-35px",
      },
      transactionNotificationsCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      prNotificationsCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      prNotification: [],
      txNotification: [],
      user: {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      formData: {
        planName: "",
      },
      prflag: true,
      txflag: true,
      showNotify: false,
      notificationId: ""
    };
  },
  computed: {
    isDisabled() {
      return (
        this.notificationSetting.toLowerCase() === "default" ||
        this.notificationSetting.toLowerCase() === "off"
      );
    },
    validateForm() {
      return true;
      return (
        !this.errors.any() &&
        this.user.currentPassword != "" &&
        this.user.newPassword != "" &&
        this.user.newPassword == this.user.confirmNewPassword
      );
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
  },
  methods: {
    ...mapActions("user", ["updateAdminProfile", "updateAdminImage"]),
    ...mapActions("page", ["fetchPagesByPartnerId"]),
    ...mapActions("admin", ["changePassword"]),
    turnOff() {
      this.txNotification = this.checkUncheck(
        false,
        this.txNotification,
        "forDashboard"
      );
      this.prNotification = this.checkUncheck(
        false,
        this.prNotification,
        "forDashboard"
      );
      this.txNotification = this.checkUncheck(
        false,
        this.txNotification,
        "forEmail"
      );

      this.prNotification = this.checkUncheck(
        false,
        this.prNotification,
        "forEmail"
      );

      this.transactionNotificationsCheckbox.forEmail = this.switchCheckUncheck(
        this.txNotification,
        "forEmail",
        false
      );
      this.transactionNotificationsCheckbox.forDashboard =
        this.switchCheckUncheck(this.txNotification, "forDashboard", false);
      this.prNotificationsCheckbox.forEmail = this.switchCheckUncheck(
        this.prNotification,
        "forEmail",
        false
      );
      this.prNotificationsCheckbox.forDashboard = this.switchCheckUncheck(
        this.prNotification,
        "forDashboard",
        false
      );
    },
    check(e, _newVal) {
      _newVal = !_newVal;
      const info = e.target.id.split("_");
      const [type, field] = info;

      if (type.includes("tx")) {
        this.txNotification = this.checkUncheck(
          _newVal,
          this.txNotification,
          field
        );
        return;
      }

      this.prNotification = this.checkUncheck(
        _newVal,
        this.prNotification,
        field
      );
    },
    switchCheckUncheck(item, field, isChecked) {
      // checks all value
      return item.every((el) => (el[field] = isChecked));
    },
    beChecked(value, field) {
      return value.every((el) => el[field]);
    },
    // checks unchecks checkbox
    checkUncheck(ischecked, values, field) {
      return values.map((el) => {
        el[field] = ischecked;
        return el;
      });
    },
    close() {
      this.formData = { planName: "" };
    },
    getNotificationsSettings(partnerId) {
      this.$store
        .dispatch(
          "merchantSettings/fetchMerchantNotificationSettingsByPartner",
          partnerId
        )
        .then((result) => {
          this.prNotification = result.data.data.prNotification;
          this.txNotification = result.data.data.txNotification;

          this.transactionNotificationsCheckbox.forEmail =
            this.switchCheckUncheck(this.txNotification, "forEmail", true);
          this.transactionNotificationsCheckbox.forDashboard =
            this.switchCheckUncheck(this.txNotification, "forDashboard", true);
          this.prNotificationsCheckbox.forEmail = this.switchCheckUncheck(
            this.prNotification,
            "forEmail",
            true
          );
          this.prNotificationsCheckbox.forDashboard = this.switchCheckUncheck(
            this.prNotification,
            "forDashboard",
            true
          );

          localStorage.setItem(
            "prNotification",
            JSON.stringify(result.data.data.prNotification)
          );
          localStorage.setItem(
            "txNotification",
            JSON.stringify(result.data.data.txNotification)
          );
          localStorage.setItem(
            "trCheckbox",
            JSON.stringify(this.transactionNotificationsCheckbox)
          );
          localStorage.setItem(
            "prCheckbox",
            JSON.stringify(this.prNotificationsCheckbox)
          );
        })
        .catch((err) => {});
    },

    editProfile() {
      this.edit = true;
    },
    cancelEdit() {
      this.edit = false;
    },
    async getPages(partnerId) {

      await this.fetchPagesByPartnerId(partnerId)
        .then((result) => {
          this.pages.push(...result.data.data);
        })
        .catch((err) => {});
    },
    switchPasswordVisibility(event, type) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-after icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-after icon-no-border"
      ) {
        if (type === "newPassword") {
          this.switchNewPasswordVisibility();
        } else if (type === "confirmPassword") {
          this.switchConfirmPasswordVisibility();
        } else {
          this.passwordFieldType =
            this.passwordFieldType === "password" ? "text" : "password";
          this.passwordShowIcon =
            this.passwordShowIcon === "icon icon-eye"
              ? "icon icon-eye-off"
              : "icon icon-eye";
        }
      }
      return true;
    },

    switchNewPasswordVisibility() {
      this.newPasswordFieldType =
        this.newPasswordFieldType === "password" ? "text" : "password";
      this.newPasswordShowIcon =
        this.newPasswordShowIcon === "icon icon-eye"
          ? "icon icon-eye-off"
          : "icon icon-eye";
    },

    switchConfirmPasswordVisibility() {
      this.confirmPasswordFieldType =
        this.confirmPasswordFieldType === "password" ? "text" : "password";
      this.confirmPasswordShowIcon =
        this.confirmPasswordShowIcon === "icon icon-eye"
          ? "icon icon-eye-off"
          : "icon icon-eye";
    },
    updateAdminProfileData() {
      if (this.dataImg) {
        let data = new FormData();

        data.append("_id", this.userDetail._id);
        data.append("file", this.newImg);

        let obj = {
          obj: data,
          config: {
            header: {
              "Content-Type": "application/json",
            },
          },
        };

        this.updateAdminImage(obj)
          .then((response) => {
            this.uploadedImage = response.data.data.file;
            this.updateUserDetail();
          })
          .catch((error) => {
            this.$vs.loading.close();

          });
      } else {
        this.updateUserDetail();
      }
    },
    changeAdminPassword() {
      const payload = {
        userDetails: this.user,
      };
      if (this.user.newPassword == this.user.confirmNewPassword) {
        this.$vs.loading();
        this.changePassword(payload)
          .then((response) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Message",
              text: response.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });

            this.showPassswordModal = false;
          })
          .catch((error) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Error",
              text: error.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        this.errors.add({
          field: "confirmNewPassword",
          msg: "The confirm new password confirmation does not match",
        });
      }
    },
    async saveNotifications() {
      this.$validator.validate("pages").then((result) => {
        if (result) {
          const payload = {};
          payload.type = this.notificationSetting.toLowerCase();
          payload.txNotification = this.txNotification.map((notification) => {
            return _.omit(notification, ["_id"]);
          });
          payload.prNotification = this.prNotification.map((notification) => {
            return _.omit(notification, ["_id"]);
          });
          const data = {
            id : this.notificationId,
            payload: payload
          }
          this.$vs.loading();
          this.$store
            .dispatch("userNotification/saveNotificationTemplates", data)
            .then((res) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Success",
                text: res.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "success",
              });
              this.isSaved = true;
              this.$router.push({ name: "dashboard" });
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: error.response.data.message,
                iconPack: "feather",
                icon: "icon-alert-circle",
                color: "danger",
              });
            });
        } else {
          this.showValidationPopUp = true;
        }
      });
    },
    updateUserDetail() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.userDetail._id = user._id;
      let data = {
        _id: user._id,
        fullName: this.userDetail.fullName,
        profileImage: this.uploadedImage,
        email: this.userDetail.email,
        phoneNumber: this.userDetail.phoneNumber,
      };
      this.updateAdminProfile(data)
        .then((res) => {
          this.$vs.loading.close();
          let responseData = res.data.data;
          responseData.token = user.token;
          localStorage.setItem("user", JSON.stringify(responseData));
          this.showProfileModal = false;

          this.$vs.notify({
            title: "Details Updated",
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });

          this.$emit("updateProfile");
        })
        .catch((error) => {
          ;
          this.$vs.notify({
            title: "Error",
            text: error.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
    },
    ...mapActions("paymentRequestTemplate", [
      "fetchRequestTemplateByMerchantId",
    ]),
    async getRequestTemplateByMerchantId(id) {
      let data = {
        merchantId: id,
        type: "hostedpage",
      };
      await this.fetchRequestTemplateByMerchantId(data).then((res) => {
        this.requestTemplates = res.data.data;
      });
    },
    async getTemplates(id){
      await this.$store
            .dispatch("userNotification/fetchNotificationTemplates", id)
            .then((result) => {
              this.requestTemplates = result.data.data;
              this.requestTemplates = this.requestTemplates.map((element) => {
                return {
                  _id: element._id,
                  paymentRequestTemplateId: element.paymentRequestTemplateId,
                  templateName: element.requesttemplate.templateName,
                  prNotification: element.prNotification,
                  txNotification: element.txNotification,
                  templateType: element.templateType,
                  type: element.type,
                  defaultPrNotification: element.requesttemplate.notifications.prNotification,
                  defaultTxNotification: element.requesttemplate.notifications.txNotification,
                }
              });
            })
            .catch((err) => {});
    },
    updateCurrImg(input) {
      if (
        input.target.files &&
        input.target.files[0] &&
        /\.(jpe?g|png|gif)$/i.test(input.target.files[0].name)
      ) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.dataImg = e.target.result;

        };
        reader.readAsDataURL(input.target.files[0]);
        this.newImg = input.target.files[0];
      }
    },
    notifyChange(e) {
      if(e == "default"){
        this.prNotification = JSON.parse(
          localStorage.getItem("defaultPrNotification")
        );
        this.txNotification = JSON.parse(
          localStorage.getItem("defaultTxNotification")
        );
        this.prNotificationsCheckbox = JSON.parse(
          localStorage.getItem("defaultPrCheckbox")
        );
        this.transactionNotificationsCheckbox = JSON.parse(
          localStorage.getItem("defaultTxCheckbox")
        );
      } else if (e == "custom") {
        this.prNotification = JSON.parse(
          localStorage.getItem("prNotification")
        );
        this.txNotification = JSON.parse(
          localStorage.getItem("txNotification")
        );
        this.prNotificationsCheckbox = JSON.parse(
          localStorage.getItem("prCheckbox")
        );
        this.transactionNotificationsCheckbox = JSON.parse(
          localStorage.getItem("trCheckbox")
        );
      } else {
        this.turnOff();
      }
    },
    checkSelected(e) {
      if(this.errors.has('pages')){
        this.$validator.errors.remove('pages');
      }
      if(e.templateType=="PRT"){
        this.prflag = true;
      }else{
        this.prflag = false;
      }
      this.notificationId = e._id;
      this.notificationSetting = e.type.toUpperCase();
      this.txNotification = e.txNotification;
      this.prNotification = e.prNotification;
      this.transactionNotificationsCheckbox.forEmail = this.beChecked(
        e.txNotification,
        "forEmail"
      );
      this.transactionNotificationsCheckbox.forDashboard = this.beChecked(
        e.txNotification,
        "forDashboard"
      );
      this.prNotificationsCheckbox.forEmail = this.beChecked(
        e.prNotification,
        "forEmail"
      );
      this.prNotificationsCheckbox.forDashboard = this.beChecked(
        e.prNotification,
        "forDashboard"
      );

      let txCheckbox = {
        forEmail: this.beChecked(
          e.defaultTxNotification,
          "forEmail"
        ),
        forDashboard: this.beChecked(
          e.defaultTxNotification,
          "forDashboard"
        )
      };
      let prCheckbox = {
        forEmail: this.beChecked(
          e.defaultPrNotification,
          "forEmail"
        ),
        forDashboard: this.beChecked(
          e.defaultPrNotification,
          "forDashboard"
        )
      };

      localStorage.setItem(
        "prNotification",
        JSON.stringify(this.prNotification)
      );
      localStorage.setItem(
        "txNotification",
        JSON.stringify(this.txNotification)
      );
      localStorage.setItem(
        "trCheckbox",
        JSON.stringify(this.transactionNotificationsCheckbox)
      );
      localStorage.setItem(
        "prCheckbox",
        JSON.stringify(this.prNotificationsCheckbox)
      );
      localStorage.setItem(
        "defaultPrNotification",
        JSON.stringify(e.defaultPrNotification)
      );
      localStorage.setItem(
        "defaultTxNotification",
        JSON.stringify(e.defaultTxNotification)
      );
      localStorage.setItem(
        "defaultPrCheckbox",
        JSON.stringify(prCheckbox)
      );
      localStorage.setItem(
        "defaultTxCheckbox",
        JSON.stringify(txCheckbox)
      );
      this.showNotify = true;
      this.$refs.notifyCollapse.maxHeight = "none !important";
    },
    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj()
          break;
        case "save":
          this.popupActive = false;
          // do save the changes
          this.saveNotifications();
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },
  },
  beforeRouteLeave(to, from, next) {  //check here
    if(this.isFormDirty && !this.isSaved){
      this.nextObj = next;
      this.popupActive = true;
    }
    else{
      next();
    }
  },
  watch: {
    // notificationSetting(newValue) {
    //   if(newValue.toString().toLowerCase() === 'off' )
    //   this.turnOff();
    // }
  },
  created() {
    let user = JSON.parse(localStorage.getItem("user"));
    const id = user._id;
    this.getNotificationsSettings(id);
    this.getPages(id);
    this.getTemplates(id);
    this.userDetail = { ...this.$store.state.AppActiveUser };
    this.userDetail.photoURL = this.userDetail.profileImage
      ? process.env.VUE_APP_API_URL +
        "uploads/profileImage/" +
        this.userDetail.profileImage
      : this.userDetail.photoURL;
    this.previousEmail = this.userDetail.email;
    this.$validator.extend("passwordFormat", {
      getMessage: () =>
        "Password must contain at least one uppercase, lowercase, digit, one special character and 10 characters long",
      validate: (value) =>
        value.match(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_-])[A-Za-z\d@$!%*?&_-]{10,}$/
        ) !== null,
    });
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";
</style>
